<template>
  <div class="Detailvedio">
    <Header></Header>
    <Nav :fromPath="fromPath"></Nav>
    <!-- 顶部 -->
    <!-- 主体部分 -->
    <div class="banner">
      <p style="width: 1200px; margin: 20px auto 0px">
        首页>{{ this.$route.query.title }}>{{ titleName }}
      </p>
      <div class="left" v-if="contentType == 1">
        <div class="shipin">
          <div class="chidlen">
            <video :src="content" width="100%" height="100%" controls></video>
          </div>
        </div>
        <div class="right">
          <h3>标题标题</h3>
          <div class="list">
            <img src="../images/123.png" alt="" />
            <p>标题标题标题</p>
          </div>
          <div class="list">
            <img src="../images/123.png" alt="" />
            <p>标题标题标题</p>
          </div>
          <div class="list">
            <img src="../images/123.png" alt="" />
            <p>标题标题标题</p>
          </div>
        </div>
      </div>
      <div class="bottom" v-if="contentType == 1">
        <h4>为你推荐</h4>
        <div class="listitem" v-for="(item, index) in 8" :key="index">
          <img src="../images/123.png" alt="" />
          <p>标题标题标题</p>
        </div>
      </div>
      <div
        v-if="contentType == 0"
        style="width: 1200px; height: 100%; margin: 50px auto"
        v-html="content"
      ></div>
    </div>
    <el-backtop :visibility-height="100"></el-backtop>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../components/header.vue";
import Footer from "../components/footer.vue";
import Nav from "../components/nav.vue";
import apiGeneralSituation from "../api/api_general_situation";
import apiNews from "../api/api_news";
import apiPopularizationOfScience from "../api/api_popularization_of_science";
import apiEucation from "../api/api_eucation";
import apiArtSpace from "../api/api_art_space";
import apiRead from "../api/api_read";
import apiWriter from "../api/api_writer";
import apiCooperation from "../api/api_cooperation";
export default {
  name: "Detailvedio",
  data() {
    return {
      titleName: "",
      fromPath: this.$route.query.fromPath,
      currentPage: 1,
      isactive: 0,
      pageNum: 1,
      pageSize: 12,
      viewType: "",
      viewslist: "",
      content: "",
      contentType: "",
      total: 0,
      viewtypelist: [
        {
          title: "全部",
          id: "",
        },
      ],
    };
  },
  components: {
    Header,
    Footer,
    Nav,
  },
  watch: {
    $route(to, from) {
      // 路由发生变化页面刷新
      // this.$router.go(0);
    },
  },
  created() {
    const { id, fromPath, title } = this.$route.query;
    this.titleName = title;
    if (id) {
      switch (fromPath) {
        case "GeneralSituation":
          // 本馆概况
          apiGeneralSituation.getDetails(id).then((res) => {
            if (res.code == 0) {
              this.content = res.data.content;
              this.contentType = res.data.contentType;
              this.titleName = res.data.title;
            }
          });
          break;
        case "News":
          // 新闻公告
          apiNews.getDetails(id).then((res) => {
            if (res.code == 0) {
              this.content = res.data.content;
              this.contentType = res.data.contentType;
              this.titleName = res.data.title;
            }
          });
          break;
        case "ArtSpace":
          // 艺术空间
          apiArtSpace.getDetails(id).then((res) => {
            if (res.code == 0) {
              this.content = res.data.content;
              this.contentType = res.data.contentType;
              this.titleName = res.data.title;
            }
          });
          break;
        case "ReadSpace":
          // 阅读空间
          apiRead.getDetails(id).then((res) => {
            if (res.code == 0) {
              this.content = res.data.content;
              this.contentType = res.data.contentType;
              this.titleName = res.data.title;
            }
          });
          break;
        case "PopularizationOfScience":
          // 科普世界
          apiPopularizationOfScience.getDetails(id).then((res) => {
            if (res.code == 0) {
              this.content = res.data.content;
              this.contentType = res.data.contentType;
              this.titleName = res.data.title;
            }
          });
          break;
        case "Eucation":
          // 家庭教育
          apiEucation.getDetails(id).then((res) => {
            if (res.code == 0) {
              this.content = res.data.content;
              this.contentType = res.data.contentType;
              this.titleName = res.data.title;
            }
          });
          break;
        case "Writer":
          // 你好作家
          apiWriter.getDetails(id).then((res) => {
            if (res.code == 0) {
              this.content = res.data.content;
              this.contentType = res.data.contentType;
              this.titleName = res.data.title;
            }
          });
          break;
        case "Cooperation":
          // 馆校合作
          apiCooperation.getDetails(id).then((res) => {
            if (res.code == 0) {
              this.content = res.data.content;
              this.contentType = res.data.contentType;
              this.titleName = res.data.title;
            }
          });
          break;
      }
    }
  },
  methods: {
    handleSizeChange(val) {
  
    },
    handleCurrentChange(val) {
     
      this.pageNum = val;
    },
    changeActive($event) {
    
    },
    removeActive($event) {
     
    },
    checktab(index, id) {
      this.isactive = index;
      this.viewType = id;
    },
  },
};
</script>
<style scope>
.Detailvedio {
  width: 100%;
  height: 100%;
  background-color: #f7f6f2;
}
/* 内容 */
.Detailvedio .banner {
  width: 100%;
  height: auto;
  padding-bottom: 100px;
}
.Detailvedio .banner .left {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  display: inline-block;
  margin-left: calc((100% - 1200px) / 2);
}
.Detailvedio .banner .left .shipin {
  width: 950px;
  height: 600px;
  background-color: #1b1611;
  display: inline-block;
  vertical-align: top;
}
.Detailvedio .banner .left .right {
  width: 230px;
  min-height: 570px;
  display: inline-block;
  vertical-align: top;
  padding: 15px 10px;
  background-color: #ffffff;
}
.Detailvedio .banner .left .right h3 {
  padding: 0px;
  margin: 0px;
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 15px;
}
.Detailvedio .banner .left .right .list {
  display: flex;
  margin-bottom: 15px;
}
.Detailvedio .banner .left .right .list img {
  width: 135px;
}
.Detailvedio .banner .left .right .list p {
  display: inline-block;
  vertical-align: top;
  margin: 0px;
  padding-left: 10px;
  font-size: 12px;
  color: #ffffff;
}
.Detailvedio .banner .bottom {
  width: 1200px;
  height: auto;
  min-height: 300px;
  margin: 0 auto;
  display: inline-block;
  margin-left: calc((100% - 1200px) / 2);
}
.Detailvedio .banner .bottom h4 {
  font-size: 16px;
}
.Detailvedio .banner .bottom .listitem {
  display: inline-block;
  width: 20%;
  margin-bottom: 20px;
}
.Detailvedio .banner .bottom .listitem img {
  width: 95%;
  margin-left: 2.5%;
}
</style>
